var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: `key_${_vm.key}` },
    [
      _c("StudentSubHeader", {
        attrs: { "show-marking-period-picker": false },
        scopedSlots: _vm._u([
          {
            key: "left-buttons",
            fn: function () {
              return [
                _c("div", { staticClass: "ml-2" }, [
                  _c("span", { staticClass: "mr-2" }, [
                    _c("i", { staticClass: "fa fa-info" }),
                  ]),
                  _c("small", [
                    _vm._v(" All Public Messages regarding student "),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
        },
        [
          _vm.$_userMixins_isSchoolUser && !_vm.$store.state.forceStudentView
            ? _c("StudentHeaderPortlet", { attrs: { "display-wide": true } })
            : _vm._e(),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-xl-4" },
              [
                _c("RecentMessages", {
                  attrs: { messages: _vm.formattedMessages },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xl-8" },
              [
                _c("MessageInbox", {
                  attrs: {
                    messages: _vm.filteredMessages,
                    "change-filter": _vm.changeFilter,
                    "show-deleted": false,
                    "toggle-show-deleted": null,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }