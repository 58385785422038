<template>
<div :key="`key_${key}`">
    <StudentSubHeader :show-marking-period-picker="false">
        <template #left-buttons>
            <div class="ml-2">
                <span class="mr-2">
                    <i class="fa fa-info" />
                </span>
                <small>
                    All Public Messages regarding student
                </small>
            </div>
        </template>
    </StudentSubHeader>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <StudentHeaderPortlet
            v-if="$_userMixins_isSchoolUser && !$store.state.forceStudentView"
            :display-wide="true"
        />

        <div class="row">
            <div class="col-xl-4">
                <RecentMessages
                    :messages="formattedMessages"
                />
            </div>

            <div class="col-xl-8">
                <MessageInbox
                    :messages="filteredMessages"
                    :change-filter="changeFilter"
                    :show-deleted="false"
                    :toggle-show-deleted="null"
                />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import Types from '../store/Types';
import globalMixins from '../store/mixins/globalMixins';
import userMixins from '../store/mixins/userMixins';
import {populate} from '../store/mixins/portfolioMixins';
import RecentMessages from '../components/Communication/RecentMessages.vue';
import MessageInbox from '../components/Communication/MessageInbox.vue';
import StudentSubHeader from './StudentSubHeader.vue';
import StudentHeaderPortlet from '../components/StudentHeaderPortlet.vue';

export default {
    name: 'StudentCommunication',
    components: {
        RecentMessages,
        MessageInbox,
        StudentSubHeader,
        StudentHeaderPortlet,
    },
    mixins: [globalMixins, userMixins],
    data() {
        return {
            key: 0,
            filter: '',
            showDeleted: false,
            saving: false,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        studentEnrollmentId() {
            return this.$route.params.studentEnrollmentId;
        },
        messages() {
            return this.$store.state.database.studentMessages.filter((m) => m.studentEnrollmentId == this.studentEnrollmentId);
        },
        formattedMessages() {
            const { messages } = this;
            return messages
                .map((m) => {
                    const message = { ...m };
                    message.lastMessageAt = moment(message.modified || message.created).format('MMM Do, YYYY');
                    message.search = `${message.messageSubject || ''} ${message.messageBody || ''} ${message.lastMessageAt || ''}`.toLowerCase();
                    return message;
                }).sort((a, b) => moment(b.modified || b.created).diff(a.modified || a.created));
        },
        databaseIsReady() {
            return Boolean(this.$store.state.database.loadState === 'READY');
        },
        filteredMessages() {
            const { formattedMessages, filter, showDeleted } = this;
            return formattedMessages.filter((m) => {
                const { deleted, search } = m;
                if ((deleted && !showDeleted) || (!deleted && showDeleted)) return false;
                if (!filter) return true;
                if ('announcement'.includes(filter.toLowerCase()) && m.isSchoolAnnouncement) return true;
                return search.includes(filter.toLowerCase());
            }).sort((a, b) => moment(b.modified || b.created).diff(a.modified || a.created));
        },
    },
    watch: {
        databaseIsReady() {
            this.populatePortfolio();
        },
        formattedMessages() {
            this.key += 1;
        },
    },
    mounted() {
        this.populatePortfolio();
    },
    methods: {
        populatePortfolio() {
            const v = this;
            const { $route, $store, databaseIsReady } = v;
            if (!databaseIsReady) return;
            populate($route, $store, v);
        },
        changeFilter(filter) {
            this.filter = filter;
        },
        toggleShowDeleted() {
            this.showDeleted = !this.showDeleted;
        },
        toggleCreateMessage() {
            this.$store.commit(Types.mutations.TOGGLE_CREATE_MESSAGE);
        },
    },
};
</script>
